import React from 'react';
import { PageBackground } from './components/PageBackground';

const TermsAndConditions = () => {
  return (
    <PageBackground>
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-8 shadow-2xl">
          <h1 className="text-4xl font-extrabold text-white mb-8 text-center">Terms and Conditions</h1>
          
          {[
            { title: "1. Introduction", content: "By signing up for our AI SMS chatbot reminder service (the 'Service'), you agree to be bound by these terms and conditions (the 'Terms'). If you do not agree to these Terms, please do not use the Service." },
            { title: "2. Consent to Receive Messages", content: "By signing up, you consent to receive automated text messages from our AI-powered chatbot. These messages are intended to serve as reminders or general guidance. Message frequency may vary based on your usage of the Service." },
            { title: "3. No Responsibility for Message Content", content: "While our AI chatbot aims to provide helpful reminders, the contents of the messages are generated by an automated system. We are not responsible for any inaccuracies, incomplete information, or potential consequences of following the chatbot's advice or instructions. Use the information provided at your own discretion." },
            { title: "4. Service Limitations", content: "The Service is provided 'as-is' and 'as available,' without any warranties of any kind. We reserve the right to modify, suspend, or discontinue the Service at any time without notice." },
            { title: "5. User Responsibilities", content: "You agree not to use the Service for any unlawful, harmful, or abusive purposes. You are responsible for ensuring that the phone number you provide is valid and that you have the right to receive text messages." },
            { title: "6. Charges", content: "Standard messaging rates from your mobile carrier may apply. We are not responsible for any charges you incur from your carrier as a result of using the Service." },
            { title: "7. Termination", content: "We reserve the right to terminate or suspend your access to the Service at any time, without notice, for any reason." },
            { title: "8. Changes to Terms", content: "We may update these Terms from time to time. Continued use of the Service constitutes your acceptance of the revised Terms." },
          ].map((section, index) => (
            <div key={index} className="mb-6">
              <h2 className="text-2xl font-bold text-white mb-2">{section.title}</h2>
              <p className="text-gray-200">{section.content}</p>
            </div>
          ))}
        </div>
      </div>
    </PageBackground>
  );
};

export default TermsAndConditions;