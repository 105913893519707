import React from 'react';
import { PageBackground } from './components/PageBackground';

const PrivacyPolicy = () => {
  return (
    <PageBackground>
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-8 shadow-2xl">
          <h1 className="text-4xl font-extrabold text-white mb-8 text-center">Privacy Policy</h1>
          
          {[
            { 
              title: "1. Information We Collect", 
              content: "We collect the following information when you sign up for the Service:",
              list: ["Your phone number", "Message interactions and responses"]
            },
            { title: "2. How We Use Your Information", content: "We use your phone number to send you automated reminder messages. Message interactions may be used to improve the chatbot's functionality and user experience." },
            { title: "3. Data Sharing", content: "We do not share your personal data with third parties unless required by law or necessary for the functioning of the Service (such as third-party SMS providers)." },
            { title: "4. Data Security", content: "We take reasonable measures to protect your personal information, but no system can be completely secure. By using the Service, you acknowledge that you are aware of and accept these risks." },
            { title: "5. Opting Out", content: "You can opt out of receiving messages at any time by replying 'STOP' to any of our messages. This will remove you from our messaging system." },
            { title: "6. Children's Privacy", content: "The Service is not intended for children under the age of 13. We do not knowingly collect information from children under 13." },
            { title: "7. Changes to This Policy", content: "We may update this Privacy Policy from time to time. Any changes will be posted on our website or communicated through the Service." },
            { title: "8. Contact Us", content: "If you have any questions or concerns about this Privacy Policy, please contact us at [insert contact information]." },
          ].map((section, index) => (
            <div key={index} className="mb-6">
              <h2 className="text-2xl font-bold text-white mb-2">{section.title}</h2>
              <p className="text-gray-200">{section.content}</p>
              {section.list && (
                <ul className="list-disc list-inside mt-2 text-gray-200">
                  {section.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </PageBackground>
  );
};

export default PrivacyPolicy;