import React, { useState, useRef, useEffect } from 'react';
import { Send, Loader2 } from 'lucide-react';

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString('en-US', { 
    hour: 'numeric', 
    minute: '2-digit',
    hour12: true 
  });
};

const MessageBubble = ({ message, isAI, createdAt }) => (
  <div className={`flex flex-col ${isAI ? 'items-start' : 'items-end'} mb-4`}>
    <div className={`max-w-[80%] rounded-2xl px-4 py-2 ${
      isAI 
        ? 'bg-white bg-opacity-10 text-white' 
        : 'bg-blue-500 text-white'
    }`}>
      <p className="text-sm sm:text-base whitespace-pre-wrap">{message}</p>
    </div>
    <span className="text-xs text-gray-400 mt-1 px-2">
      {formatTime(createdAt)}
    </span>
  </div>
);

const AutoGrowingInput = ({ value, onChange, onSubmit, disabled }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const newHeight = Math.min(textareaRef.current.scrollHeight, 96);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      placeholder="Type your message..."
      disabled={disabled}
      rows={1}
      className="flex-1 bg-white bg-opacity-10 text-white placeholder-gray-400 rounded-xl 
                 px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500
                 resize-none overflow-y-auto min-h-[48px] max-h-24
                 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-transparent"
      style={{
        lineHeight: '1.5rem',
      }}
    />
  );
};

const MessageInterface = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight;
      const targetHeight = Math.floor(vh * 0.67); // Exactly 2/3 of viewport height
      if (containerRef.current) {
        containerRef.current.style.height = `${targetHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const newMessage = inputMessage;
    setInputMessage('');
    setIsLoading(true);

    setMessages(prev => [...prev, { 
      sender: 'user',
      message: newMessage,
      created_at: new Date().toISOString()
    }]);

    try {
      const formData = new FormData();
      formData.append('From', '+1' + userId);
      formData.append('Body', newMessage);

      const response = await fetch('https://api.you-text-ai.com/messages', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Failed to send message');

      const data = await response.json();
      
      setMessages(prev => [...prev, {
        sender: 'ai',
        message: data.ai_response.response,
        created_at: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, {
        sender: 'ai',
        message: "Sorry, I couldn't process your message. Please try again.",
        created_at: new Date().toISOString()
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      ref={containerRef}
      className="h-500px flex flex-col bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-t-lg overflow-hidden"
    >
      {/* Header */}
      <div className="flex-shrink-0 flex items-center h-12 px-6 border-b border-white border-opacity-10">
        <div className="flex-1">
          <h2 className="text-lg font-semibold text-white">chat with you</h2>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto px-6 py-4">
        {messages.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-center text-gray-400">
            <p className="text-base mb-2">👋 welcome to you</p>
            <p className="text-sm">start a conversation to get help with anything!</p>
          </div>
        ) : (
          messages.map((msg, index) => (
            <MessageBubble
              key={index}
              message={msg.message}
              isAI={msg.sender === 'ai'}
              createdAt={msg.created_at}
            />
          ))
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="flex-shrink-0 border-t border-white border-opacity-10 p-4">
        <form onSubmit={handleSubmit} className="flex items-center space-x-4">
          <AutoGrowingInput
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onSubmit={handleSubmit}
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !inputMessage.trim()}
            className={`p-3 rounded-xl transition-all duration-200 flex-shrink-0 ${
              isLoading || !inputMessage.trim()
                ? 'bg-gray-600 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {isLoading ? (
              <Loader2 className="w-5 h-5 text-white animate-spin" />
            ) : (
              <Send className="w-5 h-5 text-white" />
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default MessageInterface;