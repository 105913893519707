import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MessageSquare, Shield, Zap, ArrowRight, CheckCircle2, Loader2, Info } from 'lucide-react';
import MessageInterface from './components/MessageInterface';

const API_URL = "https://api.you-text-ai.com";

// Helper Components
const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-6 bg-white bg-opacity-5 backdrop-blur-lg rounded-2xl 
                  transform transition-all duration-300 hover:scale-105 hover:bg-opacity-10">
    <div className="p-3 bg-white bg-opacity-10 rounded-xl mb-4">
      <Icon size={24} className="text-white" />
    </div>
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-200 text-center text-sm">{description}</p>
  </div>
);

const TransitionMessage = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(onComplete, 2000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="animate-fadeIn flex flex-col items-center space-y-4 py-4">
      <div className="flex items-center space-x-2 text-green-400">
        <CheckCircle2 className="w-6 h-6 animate-bounce" />
        <span className="text-lg font-medium">Successfully registered!</span>
      </div>
      <p className="text-white text-center animate-pulse">Preparing your personal AI companion...</p>
    </div>
  );
};

const LoadingButton = () => (
  <button
    disabled
    className="w-full flex justify-center items-center py-4 sm:py-5 px-6 
               rounded-xl text-lg sm:text-xl font-medium
               bg-blue-400 bg-opacity-50 text-white cursor-wait"
  >
    <Loader2 className="animate-spin mr-2" size={24} />
    <span>Checking...</span>
  </button>
);

const SubmitButton = ({ isValid }) => (
  <button
    type="submit"
    disabled={!isValid}
    className={`group w-full flex justify-center items-center py-4 sm:py-5 px-6 
               rounded-xl text-lg sm:text-xl font-medium space-x-2
               transform transition-all duration-200 ease-in-out
               ${isValid
                 ? 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                 : 'bg-gray-400 bg-opacity-20 text-gray-300 cursor-not-allowed'}`}
  >
    <span>Start Chatting</span>
    <ArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
  </button>
);

const OptInConsent = ({ checked, onChange, error }) => (
  <div className="space-y-2">
    <div className="flex items-start space-x-3">
      <div className="flex items-center h-6">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="h-5 w-5 rounded border-gray-300 text-blue-500 focus:ring-blue-500 bg-opacity-20 bg-white"
        />
      </div>
      <div className="text-sm leading-relaxed">
        <label className="text-white">
          I agree to receive SMS messages from You AI. Message frequency varies based on your usage. 
          Message & data rates may apply. Reply STOP to cancel anytime. By signing up, you agree to our{' '}
          <Link to="/terms" className="underline hover:text-blue-200">Terms of Service</Link>
          {' '}and{' '}
          <Link to="/privacy" className="underline hover:text-blue-200">Privacy Policy</Link>.
        </label>
      </div>
    </div>
    {error && (
      <div className="flex items-center space-x-1 text-red-400 text-sm">
        <Info size={14} />
        <span>{error}</span>
      </div>
    )}
  </div>
);

const PhoneNumberInput = ({ value, index, maxLength, onChange, onKeyDown, onPaste, inputRef, hasChecked, exists }) => (
  <input
    ref={inputRef}
    type="text"
    inputMode="numeric"
    maxLength={maxLength}
    className={`block ${index === 2 ? 'w-20 sm:w-24' : 'w-16 sm:w-20'} h-14 sm:h-16 
              text-center text-xl sm:text-2xl font-bold 
              bg-white bg-opacity-20 border-2 rounded-xl
              placeholder-gray-400 placeholder-opacity-70
              transition-all duration-200 ease-in-out
              ${hasChecked && value.join('').length === 10
                ? exists 
                  ? 'border-red-500 focus:border-red-500 !text-red-500' 
                  : 'border-emerald-400 focus:border-emerald-400 !text-emerald-400'
                : 'border-transparent focus:border-white text-white'
              }`}
    value={value[index]}
    onChange={(e) => onChange(index, e)}
    onKeyDown={(e) => onKeyDown(index, e)}
    onPaste={onPaste}
    placeholder={index === 2 ? '0000' : '000'}
  />
);

const LandingPage = () => {
  const [phoneNumber, setPhoneNumber] = useState(['', '', '']);
  const [checking, setChecking] = useState(false);
  const [exists, setExists] = useState(false);
  const [hasChecked, setHasChecked] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [consentError, setConsentError] = useState('');
  const inputRefs = useRef([]);
  const checkTimeoutRef = useRef(null);

  useEffect(() => {
    if (checkTimeoutRef.current) {
      clearTimeout(checkTimeoutRef.current);
    }

    const fullNumber = phoneNumber.join('');
    if (fullNumber.length === 10) {
      checkTimeoutRef.current = setTimeout(() => {
        checkPhoneNumber(fullNumber);
      }, 500);
    } else {
      setHasChecked(false);
      setExists(false);
      setError('');
    }

    return () => {
      if (checkTimeoutRef.current) {
        clearTimeout(checkTimeoutRef.current);
      }
    };
  }, [phoneNumber]);

  const handleInputChange = (index, e) => {
    setError('');
    const value = e.target.value.replace(/\D/g, '');
    const maxLengths = [3, 3, 4];
    
    if (value.length <= maxLengths[index]) {
      const newPhoneNumber = [...phoneNumber];
      newPhoneNumber[index] = value;
      setPhoneNumber(newPhoneNumber);
      
      if (value.length === maxLengths[index] && index < 2) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      if (phoneNumber[index] === '' && index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      e.preventDefault();
      inputRefs.current[index - 1].focus();
    } else if (e.key === 'ArrowRight' && index < 2) {
      e.preventDefault();
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text').replace(/\D/g, '');
    
    if (pastedText.length > 0) {
      const newPhoneNumber = ['', '', ''];
      newPhoneNumber[0] = pastedText.slice(0, 3);
      newPhoneNumber[1] = pastedText.slice(3, 6);
      newPhoneNumber[2] = pastedText.slice(6, 10);
      setPhoneNumber(newPhoneNumber);
      
      if (pastedText.length >= 10) {
        inputRefs.current[2].focus();
      }
    }
  };

  const checkPhoneNumber = async (number) => {
    setChecking(true);
    setError('');
    
    try {
      const response = await fetch(`${API_URL}/check_phone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ phone_number: number })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.detail || 'Network response was not ok');
      }
      
      const data = await response.json();
      setExists(data.exists);
      if (data.exists) {
        setUserId(number);
        setIsSuccess(true);
      }
      setHasChecked(true);
    } catch (error) {
      console.error('Error checking phone number:', error);
      setError(error.message || 'Unable to verify number');
      setExists(false);
      setHasChecked(true);
    } finally {
      setChecking(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fullNumber = phoneNumber.join('');
    
    if (!consentChecked) {
      setConsentError('Please agree to receive SMS messages');
      return;
    }
    setConsentError('');
    
    if (fullNumber.length === 10 && !exists && !checking && hasChecked) {
      setError('');
      try {
        const response = await fetch(`${API_URL}/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            phone_number: fullNumber,
            opt_in: true,
            opt_in_time: new Date().toISOString(),
            opt_in_source: 'web_form'
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Signup failed');
        }
        
        setIsSuccess(true);
        setUserId(fullNumber);
      } catch (error) {
        console.error('Error signing up:', error);
        setError('Unable to sign up');
      }
    }
  };

  const isComplete = phoneNumber.join('').length === 10;
  const isValid = isComplete && !exists && !checking && hasChecked && !error && !isSuccess && consentChecked;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 via-purple-600 to-indigo-800 relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-500 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-float" />
        <div className="absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 bg-purple-500 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-float" 
             style={{ animationDelay: '2s' }} />
        <div className="absolute left-1/4 bottom-1/4 w-1/2 h-1/2 bg-indigo-500 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-float"
             style={{ animationDelay: '4s' }} />
      </div>

      <div className="relative min-h-screen flex flex-col items-center px-4 py-8 sm:py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-4xl space-y-8 sm:space-y-12">
          {/* Hero Section */}
          <div className="text-center space-y-6">
            <h1 className="text-6xl sm:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-white to-blue-200 
                         transform hover:scale-105 transition-transform duration-300">
              You
            </h1>
            <p className="text-xl sm:text-2xl font-light text-blue-100 max-w-2xl mx-auto leading-relaxed">
              Your personal AI companion that grows with you
            </p>
          </div>

          {/* Dynamic Content Section */}
          <div className="transition-all duration-500 ease-in-out">
            {!userId ? (
              <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl p-6 sm:p-8 shadow-2xl
                            transform hover:bg-opacity-15 transition-all duration-300 max-w-xl mx-auto">
                <div className="text-center space-y-4 mb-8">
                  <h2 className="text-2xl sm:text-3xl font-bold text-white">
                    Get Started in Seconds
                  </h2>
                  <p className="text-lg text-blue-100">
                    Just enter your phone number — that's all you need
                  </p>
                </div>

                {isSuccess ? (
                  <TransitionMessage onComplete={() => setShowChat(true)} />
                ) : (
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex items-center justify-center space-x-2">
                      {[0, 1, 2].map((index) => (
                        <React.Fragment key={index}>
                          <PhoneNumberInput
                            value={phoneNumber}
                            index={index}
                            maxLength={index === 2 ? 4 : 3}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            onPaste={handlePaste}
                            inputRef={el => inputRefs.current[index] = el}
                            hasChecked={hasChecked}
                            exists={exists}
                            />
                          {index < 2 && (
                            <span className="text-white text-2xl sm:text-3xl font-light">·</span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>

                    {error && (
                      <p className="text-red-500 text-center animate-fadeIn">{error}</p>
                    )}

                    {exists && (
                      <p className="text-yellow-400 text-center animate-fadeIn">
                        This number is already registered. Continue to chat!
                      </p>
                    )}

                    <OptInConsent 
                      checked={consentChecked}
                      onChange={(e) => {
                        setConsentChecked(e.target.checked);
                        if (e.target.checked) setConsentError('');
                      }}
                      error={consentError}
                    />

                    {checking ? (
                      <LoadingButton />
                    ) : (
                      <SubmitButton isValid={isValid} />
                    )}
                  </form>
                )}
              </div>
            ) : !showChat ? (
              <div className="max-w-xl mx-auto">
                <TransitionMessage onComplete={() => setShowChat(true)} />
              </div>
            ) : (
              <div className="w-full max-w-4xl mx-auto animate-fadeIn bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden">
                <MessageInterface userId={userId} />
              </div>
            )}
          </div>

          {/* Features Grid - Visible only before chat */}
          {!showChat && (
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 max-w-4xl mx-auto transition-opacity duration-500">
              <FeatureCard 
                icon={MessageSquare}
                title="Natural Chat"
                description="Chat naturally with an AI that understands context and remembers your style"
              />
              <FeatureCard 
                icon={Zap}
                title="Instant Help"
                description="Get immediate assistance 24/7 for reminders, advice, or friendly conversation"
              />
              <FeatureCard 
                icon={Shield}
                title="Secure & Private"
                description="Your conversations stay private with end-to-end encryption"
              />
            </div>
          )}

          {/* Footer - Visible only before chat */}
          {!showChat && (
            <div className="text-center text-sm transition-opacity duration-500">
              <p className="text-blue-100">
                By signing up, you agree to our{' '}
                <Link to="/terms" className="font-medium text-white hover:text-blue-200 underline underline-offset-2">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to="/privacy" className="font-medium text-white hover:text-blue-200 underline underline-offset-2">
                  Privacy Policy
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;